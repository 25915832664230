.app-sidenav {
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    border-right: 1px solid $border-color;
    width: $height-header-mobile - 1px;
    padding: 15px 10px;

    @include transition(all 0.3s);

    @include media-breakpoint-up(lg) { width: $height-header; }

    @include media-breakpoint-down(sm) { display:none; }

    .nav-link {
        padding: 0;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-text-02;
        @include border-radius();
        @include transition($transition-base);

        @include hover-focus() {
            color: $color-text-02;
        }

        &.active {
            color: $color-brand-01;
            svg { fill: rgba($color-brand-01, .2); }
        }

        + .nav-link { margin-top: 10px; }

        svg {
            width: 20px;
            stroke-width: 2.2px;
            fill: rgba($color-text-02, .1);
        }
    }
}
