.noty_modal{
    opacity: 0.7 !important
}

.noty_layout{

    &#noty_layout__topRight, &#noty_layout__topLeft{
        top: 70px;
    }

    i{
         display: block;
         text-align: center;
         margin: 20px 0;
         font-size: 40px;
    }

    .noty_type__alert, .noty_type__error{
        i{
            color: $danger;
        }
    }
}
