
.tooltip-inner {
  font-size: 12px;
  padding: 5px 10px;
}

.tooltip-primary { @include tooltip-variant($blue); }
.tooltip-secondary { @include tooltip-variant($gray-600); }
.tooltip-success { @include tooltip-variant($green); }
.tooltip-danger { @include tooltip-variant($red); }
.tooltip-warning { @include tooltip-variant($yellow); }
.tooltip-info { @include tooltip-variant($cyan); }
.tooltip-light { @include tooltip-variant($gray-400); }
.tooltip-dark { @include tooltip-variant($gray-800); }
.tooltip-black { @include tooltip-variant(#000); }


.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-left .arrow{
    top: 50%;
    transform: translateY(-50%);
}

.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow{
    left: 50%;
    transform: translateX(-50%);
}
