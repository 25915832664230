@charset 'utf-8';

$fa-font-path: "../fonts/font-awesome/" !default;
$ionicons-font-path: "../fonts/ionicons/" !default;

/* ############### FONTS IMPORT ############### */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400');
//FontAwesome
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
//IonIcons
@import "../../node_modules/ionicons/dist/scss/ionicons";

// Local font
@import "fonts/ibm-plex-sans";
@import "fonts/inter-ui";

// Variables
@import "variables";
@import "bootstrap/variables";

// Mixins
@import "mixins";
@import "bootstrap/mixins";

/* ############### BOOTSTRAP FRAMEWORK ############### */
@import "../../node_modules/bootstrap/scss/bootstrap";

// Import dependancy styles
@import "../../node_modules/select2/dist/css/select2.min";
@import "../../node_modules/sweetalert2/dist/sweetalert2";
@import "../../node_modules/components-jqueryui/themes/base/core";
@import "../../node_modules/components-jqueryui/themes/base/datepicker";
//@import "../../node_modules/components-jqueryui/themes/base/tooltip";
@import "../../node_modules/components-jqueryui/themes/base/theme";
@import "../../node_modules/bootstrap-tour/build/css/bootstrap-tour-standalone.min";
@import "../../node_modules/fullcalendar/dist/fullcalendar";
@import "../../node_modules/jkanban/dist/jkanban.min";
@import "../../node_modules/perfect-scrollbar/css/perfect-scrollbar";
@import "../../node_modules/x-editable/dist/bootstrap3-editable/css/bootstrap-editable";
@import "../../node_modules/noty/lib/noty";
@import "../../node_modules/noty/lib/themes/bootstrap-v4";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";


/* ############### BOOTSTRAP OVERRIDES ############### */
@import "bootstrap/reboot";
@import "bootstrap/alert";
@import "bootstrap/badge";
@import "bootstrap/breadcrumb";
@import "bootstrap/buttons";
@import "bootstrap/card";
@import "bootstrap/carousel";
@import "bootstrap/custom-forms";
@import "bootstrap/dropdown";
@import "bootstrap/forms";
@import "bootstrap/grid";
@import "bootstrap/input-group";
@import "bootstrap/list-group";
@import "bootstrap/modal";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/pagination";
@import "bootstrap/popover";
@import "bootstrap/progress";
@import "bootstrap/toast";
@import "bootstrap/tooltip";
@import "bootstrap/table";
@import "bootstrap/transitions";

/* ############### ADDON COMPONENTS STYLES ############### */
@import "dashforge/accordion";
@import "dashforge/avatar";
@import "dashforge/button-group";
@import "dashforge/card";
@import "dashforge/divider";
@import "dashforge/dropdown";
@import "dashforge/image";
@import "dashforge/link";
@import "dashforge/list";
@import "dashforge/marker";
@import "dashforge/modal";
@import "dashforge/nav";
@import "dashforge/off-canvas";
@import "dashforge/overlay";
@import "dashforge/placeholder";
@import "dashforge/search";
@import "dashforge/steps";
@import "dashforge/tab";

/* ############### PLUGINS STYLES ############### */
@import "lib/datatables";
@import "lib/datepicker";
@import "lib/flot";
@import "lib/jquery-steps";
@import "lib/morris";
@import "lib/parsley";
@import "lib/jqvmap";
@import "lib/quill";
@import "lib/rangeslider";
@import "lib/scrollbar";
@import "lib/select2";
@import "lib/spectrum";
@import "lib/tagsinput";
@import "lib/typeahead";
@import "lib/noty";



/* ############### PANEL STYLES ############### */
@import "panels/backdrop";
@import "panels/header";
@import "panels/logo";
@import "panels/sidebar";
@import "panels/aside";
@import "panels/content";
@import "panels/footer";
@import "panels/settings";
@import "panels/widgets";
@import "panels/sidenav";

/* ############### UTILITIES/HELPER CLASSES ############### */
@import "util/background";
@import "util/border";
@import "util/height";
@import "util/margin";
@import "util/misc";
@import "util/padding";
@import "util/position";
@import "util/transform";
@import "util/typography";
@import "util/width";

/* ############### BLUSQUID CUSTOM STYLES ############### */
@import "blusquid/custom";
