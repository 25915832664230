/** blusquid edit **/
a:focus {
    outline: none !important;
}

#chatForm {
    width: 100%;
    padding: 0;
    height: 100%;
}

#clickToChat {
    font-size: 14pt;
}

#chatActivityIndicator {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
}

#chatActivityIndicator img {
    width: 50px;
    top: 50%;
    position: fixed;
}

#allTeams a {
    cursor: pointer;
    display: block;
}

#allTeams a span.newMessageCounter {
    display: none;
    float: right;
    background: #dc3545;
    color: #fff;
    width: 19px;
    height: 19px;
    text-align: center;
    border-radius: 25px;
    padding: 1px 0;
    font-size: 8pt;
}

ul#addUsersToChannelList {
    padding: 0;
    list-style-type: none;
    width: 100%;
}

ul#addUsersToChannelList li a {
    padding: 10px;
    border-radius: 5px;
    background: #f3f3f3;
    margin-bottom: 10px;
    display: block;
    cursor: pointer;
}

#channelNav {
    display: none;
}

.chat-group {
    background: url("/img/svg/blusquid_colour_megaphone.svg") bottom right / 50% no-repeat;
}

.chat-group .media + .media {
    margin-top: 50px !important;
}

.chat-group .rightMargin {
    margin-right: 20px;
    margin-left: 0px;
}

#typingIndicator {
    position: absolute;
    margin: 15px 0 0;
    font-size: 8pt;
    color: #b3b3b3;
}

.messageContentWrapMe {
    float: right;
    max-width: 45%;
    overflow: hidden;
}

.messageContentMe {
    background: #34495e;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    text-align: left;
    min-width: 80px;
    float: right;
}

.messageContentOther {
    background: #3498db;
    max-width: 52%;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    float: left;
    min-width: 80px;
}

.mcValue{
    p,h1,h2,h3,h4,h5,h6,pre{
        color: #F3F3F3;
    }
    a{
        color: #F3F3F3;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
}

.langSelector {
    margin-top: 20px !important;
}

.dropdown-notification {
    margin-right: 20px !important;
}

.statusSelector {
    margin-top: 21.5px !important;
}

.statusSelector a {
    cursor: pointer;
}

.usersStatus span {
    width: 10px !important;
    height: 10px !important;
    top: 5px !important;
    right: 5px !important;
}

.users-status-list-item span {
    width: 10px;
    height: 10px;
    margin: 10px 0 -14px -18px;
    display: block;
    border-radius: 5px;
}

span.usersStatus1 {
    background-color: green !important;
}

span.usersStatus2 {
    background-color: green !important;
}

span.usersStatus3 {
    background-color: yellow !important;
}

span.usersStatus4 {
    background-color: orange !important;
}

span.usersStatus5 {
    background-color: red !important;
}

span.usersStatus6 {
    background-color: red !important;
}

span.usersStatus7 {
    background-color: grey !important;
}

.dropdown-link .usersStatus {
    margin: 0 0 -19px 0;
}

.avatar-offline::after,
.avatar-online::after,
.avatar-active::after,
.avatar-snooze::after,
.avatar-away::after,
.avatar-busy::after,
.avatar-unavailable::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 2px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    box-shadow: 0 0 0 2px #fff; }

.avatar-offline::after {
    background-color: #c0ccda; }

.avatar-online::after {
    background-color: #10b759; }

.avatar-active::after {
    background-color: #10b759; }

.avatar-snooze::after {
    background-color: yellow; }

.avatar-away::after {
    background-color: orange; }

.avatar-busy::after {
    background-color: red; }

.avatar-unavailable::after {
    background-color: red; }

.like-flag-btn {
    width: 100%;
    height: 30px;
    margin: 17px 0px 0 -10px;
    color: #b3b3b3;
    float: right;

    a{
        cursor: pointer;
        margin: 0 11px 0 0px;
        color: #b3b3b3;

        &.like-btn, &.like-button{
            .green{
                color: #2bb673;
            }

            &:hover{
                i{
                    color: #2bb673;
                }
            }
        }

        &.flag-btn{
            .flagged{
                color: #e67e22;
            }
            &:hover{
                i{
                    color: #e67e22;
                }
            }
        }
    }

    i {
         font-size: 15px;
    }
}


.lfb-right {
    right: 69px;
    text-align: right;
}

.lfb-right a {
    margin: 0 0 0 11px !important;
}

.likes-count {
    color: #fff;
    width: 38px;
    border-radius: 15px;
    text-align: right;
    padding: 0px 4px 0px 4px;
    margin: 0px 0 -20px 0;
    height: 25px;
    border: 2px solid #f8f9fc;
}

.likes-count i {
    font-size: 10pt;
    float: left;
    margin: 3.5px 0 0;
}

.likes-count-int {
    margin: -4px 0 0;
}

.likes-count-left {
    background: #3498db;
    float: right;
}

.likes-count-left i {
    color: #34495e;
}

.likes-count-right {
    background: #34495e;
    float: left;
}

.likes-count-right i {
    color: #3498db;
}

.liked {
    color: #2ecc71;
}

.flagged {
    color: #e67e22;
}

.og-data {
    display: none;
    background: #FFFFFF;
    border: 1px solid #e8e8e8;
    right: 69px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    max-width: 550px;
}

.og-data-right {
    float: right;
}

.og-image {
    width: 100%;
}

.og-title {
    text-align: left;
    font-weight: bold;
    padding: 10px;
}

.og-description {
    text-align: left;
    padding: 10px;
}

a#add-calendar svg {
    cursor: pointer;
    float: right;
    height: 20px;
    color: #b3b3b3;
    border: 1.4px solid;
    width: 20px;
    border-radius: 10px;
}

/** Upcoming Event **/
#upcoming-events {
    display: flex;
    flex-direction: column;
}

/** Spinner **/
@keyframes ldio-2wmhfqmoz34 {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-2wmhfqmoz34 div {
    position: absolute;
    width: 120px;
    height: 120px;
    border: 20px solid #ffffff;
    border-top-color: transparent;
    border-radius: 50%;
}
.ldio-2wmhfqmoz34 div {
    animation: ldio-2wmhfqmoz34 1s linear infinite;
    top: 100px;
    left: 100px
}
.loadingio-spinner-rolling-un46ajgflui {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    //background: rgba(NaN, NaN, NaN, 0);
}
.ldio-2wmhfqmoz34 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-2wmhfqmoz34 div { box-sizing: content-box; }
/* generated by https://loading.io/ */

#calendar-nav button {
    color: #b3b3b3;
    float: right;
    margin: -25px 0 0;
    background: transparent;
    border: none;
}

.page-wrapper{
    background-color: lighten($color-ui-01, 1%);
    position: relative;
    top: $height-header-mobile;
    //
    //@include media-breakpoint-up(lg) { top: $height-header; }
}

//.tooltip-blusquid {
//    display:none;
//    background: $primary;
//    font-size:12px;
//    //height:20px;
//    //width:80px;
//    //padding:10px;
//    color:#fff;
//    //z-index: 99;
//    //bottom: 10px;
//    border: 2px solid white;
//    /* for IE */
//    //filter:alpha(opacity=80);
//    ///* CSS3 standard */
//    //opacity:0.8;
//}

.editable-click, a.editable-click, a.editable-click:hover{
    border-bottom: none;
}

.select2-dropdown{
    z-index: 9999;
}

body.app-projects-single{
    .footer{
        margin-top: 55px;
    }
}
