.fade {
    @include transition($transition-fade);
    opacity: 0;

    &.show, &.in{
        opacity: 1;
    }
}

.collapse {
    &:not(.show) {
        display: none;
    }
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    @include transition($transition-collapse);
}
